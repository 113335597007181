<template>
  <div id="index">
    <!--  轮播图  -->
    <div class="carousel_warp">
      <el-carousel trigger="click" height="500px" :autoplay="'1' === baseInfo.carousel" :interval="this.baseInfo.timeInterval * 1000">
        <el-carousel-item v-for="item in carousels" :key="item.id">
          <el-image v-show="item.fileId" style="width: 100%; height: 100%" :src="preview + item.fileId" fit="cover">
            <div slot="error" class="image-slot">
              <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
            </div>
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--    公司概况-->
    <div class="company_profile_warp">
      <div class="company_profile">
        <div class="company_profile_left">
          <div class="profile_left_logo">
            <p class="logo_main">COMPANY</p>
            <p class="logo_branch">PROFILE</p>
          </div>
          <div class="profile_left_title">公司概况</div>
          <div class="profile_left_txt" v-show="companyProfile.coProfile" v-html="companyProfile.coProfile"></div>
        </div>
        <div class="company_profile_right">
          <el-image v-if="companyProfile.fileId" style="width: 520px; height: 340px" :src="preview + companyProfile.fileId" fit="cover">
            <div slot="error" class="image-slot">
              <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
            </div>
          </el-image>
        </div>
      </div>
    </div>
    <!--    新闻中心-->
    <div class="news_warp">
      <div class="news">
        <div class="news_title">
          <div class="news_title_logo">INFORMATION&emsp;CENTER</div>
          <div class="news_title_item">新闻中心</div>
          <div class="news_title_menu"><span>公司新闻</span></div>
        </div>
        <!--   新闻轮播    -->
        <el-carousel height="420px" :autoplay="'1' === baseInfo.carousel" :interval="this.baseInfo.timeInterval * 1000" type="card" indicator-position="outside">
          <el-carousel-item v-for="(item, index) in news.data" :key="index">
            <div class="news_item">
              <el-link @click="articleClick(3, item.id)" :underline="false">
                <!-- <el-card class="box-card" shadow="hover" style="width: 380px; height: 415px"> -->
                <div class="news_item_img">
                  <el-image style="width: 100%; height: 100%" :src="preview + item.fileId" fit="cover">
                    <div slot="error" class="image-slot">
                      <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                    </div>
                  </el-image>
                  <p>{{ item.arTitle }}</p>
                </div>
                <div class="news_item_summary">{{ item.arAbstract }}</div>
                <div class="news_link">
                  <div class="news_link_left">{{ item.releaseTime }}</div>
                  <div class="news_link_right">了解详情</div>
                </div>
                <!-- </el-card> -->
              </el-link>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!--  安居项目  -->
    <div class="project_warp">
      <div class="project_title">
        <div class="project_title_logo">COMBINE&emsp;CENTER</div>
        <div class="project_title_item">安居项目</div>
        <div class="project_title_menu">
          <span class="project_menu_item" @click="baseProjectTabClick(1)" :class="[1 === projectTabIndex ? 'projectTabActive' : '']">人才安居</span>
          <span class="project_menu_item" @click="baseProjectTabClick(2)" :class="[2 === projectTabIndex ? 'projectTabActive' : '']">莞寓住房</span>
        </div>
      </div>
      <div class="project_item">
        <!--   人才安居     -->

        <el-carousel height="420px" indicator-position="outside" :autoplay="'1' === baseInfo.carousel" :interval="this.baseInfo.timeInterval * 1000" type="card" v-if="1 === projectTabIndex">
          <el-carousel-item v-for="item in project.data" :key="item.id">
            <div class="project_info_item">
              <el-link @click="pidClick(item.id)" :underline="false">
                <!-- <el-card class="box-card" shadow="hover" style="width: 380px; height: 415px"> -->
                <div class="project_info_item_img">
                  <el-image style="width: 100%; height: 100%" :src="preview + item.fileId" fit="cover">
                    <div slot="error" class="image-slot">
                      <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                    </div>
                  </el-image>
                  <p>{{ item.itemName }}</p>
                </div>
                <div class="project_info_item_summary">{{ item.synopsis }}</div>
                <div class="project_info_link">
                  <div class="project_info_link_left">{{ item.createTime }}</div>
                  <div class="project_info_link_right">了解详情</div>
                </div>
                <!-- </el-card> -->
              </el-link>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!--   莞寓住房     -->
        <div class="project_info" v-if="2 === projectTabIndex">
          <div class="project_info_warp">
            <div class="project_info_left">
              <div class="info_left_item">
                <el-image class="guanyu_logo" style="width: 120px; height: 68px; border: 0" :src="preview + guanyu.logo" fit="fill">
                  <div slot="error" class="image-slot">
                    <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                  </div>
                </el-image>
                <el-image style="width: 700px; height: 360px; border: 0; border-radius: 5px" :src="preview + guanyu.fileId" fit="fill">
                  <div slot="error" class="image-slot">
                    <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                  </div>
                </el-image>
              </div>
            </div>
            <div class="project_info_right" v-html="guanyu.housingProfile"></div>
          </div>
        </div>
      </div>
    </div>
    <!--  招标采购信息  -->
    <div class="bidding_warp">
      <div class="bidding">
        <div class="bidding_title_warp">
          <div class="bidding_title_main">招标采购信息</div>
          &emsp;
          <div class="bidding_title_branch">Tendering and bidding</div>
        </div>
        <div class="bidding_list_warp">
          <div class="bidding_list_left">
            <div class="list_tile">
              <div class="list_tile_left">招标信息公告</div>
              <el-link :underline="false">
                <div class="list_title_right" @click="moreClick('bidding_information')">查看更多</div>
              </el-link>
            </div>
            <div class="list_content">
              <el-link @click="articleClick(6, item.id)" v-for="item in biddings" :key="item.id" :underline="false">
                <div class="list_item">
                  <div class="list_item_title">
                    {{ item.arTitle }}
                  </div>
                  <div class="list_item_link">
                    {{ item.releaseTime }}
                  </div>
                </div>
              </el-link>
            </div>
          </div>
          <div class="bidding_list_right">
            <div class="list_tile">
              <div class="list_tile_left">中标信息公告</div>
              <el-link :underline="false">
                <div class="list_title_right" @click="moreClick('bid_winning_information')">查看更多</div>
              </el-link>
            </div>
            <div class="list_content">
              <el-link @click="articleClick(6, item.id)" v-for="item in winBiddings" :key="item.id" :underline="false">
                <div class="list_item">
                  <div class="list_item_title">
                    {{ item.arTitle }}
                  </div>
                  <div class="list_item_link">
                    {{ item.releaseTime }}
                  </div>
                </div>
              </el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  公司领导人  -->
    <div class="leader_warp">
      <div class="leader">
        <div class="leader_title_warp">
          <div class="leader_title_main">公司领导人</div>
          &emsp;
          <div class="leader_title_branch">LEASDERSHIP RANKS</div>
        </div>
        <div class="leader_list_warp">
          <el-carousel trigger="click" height="360px" :autoplay="'1' === baseInfo.carousel" :interval="this.baseInfo.timeInterval * 1000">
            <el-carousel-item v-for="item in leaders" :key="item.id">
              <div class="leader_info_warp">
                <div class="info_warp_left">
                  <el-image style="width: 320px; height: 300px; border-radius: 5px" :src="preview + item.fileId" fit="fit">
                    <div slot="error" class="image-slot">
                      <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                    </div>
                  </el-image>
                </div>
                <div class="info_warp_right">
                  <div class="info_base_left">
                    <div class="info_base_name">{{ item.ldName }}</div>
                    <div class="info_base_position" style="font-size: 20px">{{ item.jobAbridge }}</div>
                  </div>
                  <div class="info_base_right">
                    <div>{{ item.jobFullName }}</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!--  党建专栏  -->
    <div class="party_warp">
      <div class="party">
        <div class="party_title_warp">
          <div class="party_title_main">党建专栏</div>
          &emsp;
          <div class="party_title_branch">PARTY BUILDING COLUMN</div>
        </div>
        <div class="party_list_warp">
          <el-link @click="articleClick(3, item.id)" v-for="item in partys" :key="item.id" :underline="false">
            <div class="list_item">
              <el-card class="box-card" shadow="hover" style="width: 100%">
                <div class="list_item_left" style="width: 302px">
                  <div class="item_left_img">
                    <el-image style="width: 302px; height: 292px; border-radius: 5px" :src="preview + item.fileId" fit="cover">
                      <div slot="error" class="image-slot">
                        <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                      </div>
                    </el-image>
                  </div>
                </div>
                <div class="list_item_right" style="width: 291px">
                  <div class="item_right_tile">{{ item.arTitle }}</div>
                  <div class="item_right_summary">{{ item.arAbstract }}</div>
                </div>
                <div class="mask">
                  <div class="hover-title">了解详情</div>
                </div>
              </el-card>
            </div>
          </el-link>
        </div>
      </div>
    </div>
    <!--  联系方式  -->
    <div class="index_contact_warp">
      <div class="index_contact">
        <div class="contact_title_warp">
          <div class="contact_title_main">CONTACT&emsp;INFORMATION</div>
          <div class="contact_title_branch">联系方式</div>
        </div>
        <div class="contact_list_warp">
          <div class="contact_item">
            <div class="contact_item_icon">
              <el-image style="width: 90px; height: 90px; border-radius: 5px" src="./statics/icon/pages/index/address.png" fit="cover">
                <div slot="error" class="image-slot">
                  <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                </div>
              </el-image>
            </div>
            <div class="contact_item_txt">{{ baseInfo.coAddress }}</div>
          </div>
          <div class="contact_item">
            <div class="contact_item_icon">
              <el-image style="width: 90px; height: 90px; border-radius: 5px" src="./statics/icon/pages/index/dianhua.png" fit="cover">
                <div slot="error" class="image-slot">
                  <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                </div>
              </el-image>
            </div>
            <div class="contact_item_txt">{{ baseInfo.coLandline }}</div>
          </div>
          <div class="contact_item">
            <div class="contact_item_icon">
              <el-image style="width: 90px; height: 90px; border-radius: 5px" src="./statics/icon/pages/index/email.png" fit="cover">
                <div slot="error" class="image-slot">
                  <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                </div>
              </el-image>
            </div>
            <div class="contact_item_txt">{{ baseInfo.coMailbox }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "index",
  props: {
    pageIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      defalutUrl: require("../../public/statics/error/zwtp.jpg"),
      //轮播图
      carousels: [],
      //公司概况
      companyProfile: {},
      //新闻数据对象
      news: {
        //新闻数据
        data: [],
        //新闻最大轮播上限
        max: 5
      },
      //安居项目tab下标
      projectTabIndex: 1,
      //人才安居
      project: {
        //人才安居数据
        data: [],
        //人才安居最大轮播上限
        max: 5
      },
      //莞寓住房
      guanyu: {},
      //招标信息
      biddings: [],
      //中标信息
      winBiddings: [],
      //领导人信息
      leaders: [],
      //党建专栏
      partys: [],
      //联系方式
      baseInfo: {}
    }
  },
  watch: {
    //监听路由变化
    $route: {
      handler() {
        this.getData()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //安居项目tab点击
    baseProjectTabClick(index) {
      this.projectTabIndex = index
    },
    //人才安居详情点击
    pidClick(id) {
      this.$router.push({ path: "/pid", query: { id: id } })
    },
    //文章详情点击（包括：公司新闻、项目动态、党建专栏、项目成果、行政法规、行政动态、招标信息、中标信息）
    articleClick(page, id) {
      console.log(page, id, "page")
      // this.$router.push({path: "/article", query: {"id": id}});
      this.$router.push({ path: "/article", query: { active: page, id: id } })
    },
    //招标信息、中标信息查看更多点击
    moreClick(arCatalog) {
      this.$router.push({ path: "/bidInfo", query: { active: 6, arCatalog: arCatalog } })
      // this.$router.push("/bidInfo?arCatalog=" + arCatalog);
      // this.$emit('pageChange', {type: 'header_compo_tab_switch', data: 6});
    },
    //获取数据
    getData() {
      this.getInfo()
      this.getCarousels()
      this.getProfileInfo()
      this.getNews()
      this.getProjects()
      this.getGuanYuHousing()
      this.getBiddings(0)
      this.getWinBiddings(0)
      this.getLeaders()
      this.getPartys(0)
    },
    //获取官网基本信息
    getInfo() {
      this.request.get("/baseinfo/getInfo?key=" + this.config.key).then((res) => {
        this.baseInfo = res.data
      })
    },
    //获取轮播图列表
    getCarousels() {
      this.request.get("/carousel/getCarouselList?key=" + this.config.key).then((res) => {
        this.carousels = res.data
      })
    },
    //获取公司概况信息
    getProfileInfo() {
      this.request.get("/profile/getProfileInfo?key=" + this.config.key).then((res) => {
        this.companyProfile = undefined === res.data ? {} : res.data
      })
    },
    //新闻轮播列表
    getNews() {
      this.request.get("/article/list?key=" + this.config.key + "&pageNum=" + 1 + "&pageSize=5&arCatalog=news").then((res) => {
        this.news.data = res.data
      })
    },
    //人才安居轮播列表
    getProjects() {
      this.request.get("/item/list?key=" + this.config.key + "&pageNum=" + 1 + "&pageSize=5").then((res) => {
        this.project.data = res.data
      })
    },
    //莞寓住房
    getGuanYuHousing() {
      this.request.get("/housing/getInfo?key=" + this.config.key).then((res) => {
        this.guanyu = undefined === res.data ? {} : res.data
      })
    },
    //招标信息列表
    getBiddings(index) {
      let _index = undefined === index ? 1 : index + 1
      this.request.get("/article/list?key=" + this.config.key + "&pageNum=" + _index + "&pageSize=6&arCatalog=bidding_information").then((res) => {
        this.biddings = res.data
      })
    },
    //中标信息列表
    getWinBiddings(index) {
      let _index = undefined === index ? 1 : index + 1
      this.request.get("/article/list?key=" + this.config.key + "&pageNum=" + _index + "&pageSize=6&arCatalog=bid_winning_information").then((res) => {
        this.winBiddings = res.data
      })
    },
    //领导人列表
    getLeaders() {
      this.request.get("/leader/getLeaderList?key=" + this.config.key).then((res) => {
        this.leaders = res.data
      })
    },
    //党建信息列表
    getPartys(index) {
      let _index = undefined === index ? 1 : index + 1
      this.request.get("/article/list?key=" + this.config.key + "&pageNum=" + _index + "&pageSize=4&arCatalog=party_building_column").then((res) => {
        this.partys = res.data
      })
    }
  }
}
</script>
<style>
@import "../assets/styles/pages/index.css";
</style>
